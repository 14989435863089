import globalApi from '@/request/globalApi/globalApi'

// SLOT MANAGEMENT
export const fetchActiveSlotManagementRequest = params => globalApi.get('api/active-slot-management', { params })

export const fetchActiveSlotAirports = () => globalApi.get('api/active-slot-management/airports')

export const fetchCancelledSlotManagementRequest = params => globalApi.get('api/cancelled-slot-management', { params })

export const fetchCancelledSlotAirports = () => globalApi.get('api/cancelled-slot-management/airports')
